import React from "react"
import Layout from "../../components/layout/Layout"
import MainTitle from "../../components/titles/MainTitle"
import Section from "../../components/section/Section"
import CardArticle from "../../components/card/CardArticle"
import ButtonLink from "../../components/button/ButtonLink"
import css from "./Home.module.css"
import IconHouse from "../../images/house.svg"
import PresentationIMG from "../../images/presentation.jpg"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout type="HMF" className={css.home}>
      <MainTitle
        title="Bienvenidas"
        description="Somos un espacio de vida y cambio para la mujer"
        icon={<IconHouse />}
        theme="home"
      />
      <Section className={css.presentation}>
        <div>
          <p>
            Desde 1978 el <span>Movimiento Manuela Ramos</span> trabaja
            incansablemente en la promoción y defensa de la autonomía de las
            mujeres, aportando a la construcción de la democracia y de
            relaciones de género equitativas.
          </p>
        </div>
        <ButtonLink url="/nosotras" className={css.linkToAbout}>
          Conoce más sobre nosotras
        </ButtonLink>
        <img src={PresentationIMG} alt="1987" />
      </Section>
      <Section className={css.volunteering}>
        <div>
          <p>
            En el Movimiento Manuela Ramos trabajamos por el empoderamiento
            social, económico y político de mujeres y disidencias. Participamos
            de nuevas formas de expresión de los feminismos en espacios
            intergeneracionales de aprendizaje y lucha.
          </p>
        </div>
        <ButtonLink url="/feminismo" className={css.linkToFeminism}>
          Únete a nuestras actividades
        </ButtonLink>
      </Section>

      <Section className={css.grids}>
        <h1>Últimos artículos</h1>
        <div className={css.articles}>
          {data.articles.slice(0, 6).map((item, i) => (
            <CardArticle
              key={i}
              url={`${url}/${item.slug}`}
              cover={item.portada.file.url}
              date={item.fecha}
              title={item.titulo}
            />
          ))}
        </div>
        <ButtonLink url="/blog" className={css.more}>
          Ver todos los artículos
        </ButtonLink>
      </Section>

      <Section className={css.credimujer}>
        <div>
          <h1>Credimujer</h1>
          <p>
            Gracias a la Banca Comunal de CrediMujer, el Movimiento Manuela
            Ramos fomenta el empoderamiento y autonomía económica de mujeres
            emprendedoras.
          </p>
          <p>
            Hoy, más de 17 mil mujeres en todo el Perú acceden a recursos
            financieros y fortalecen sus capacidades para la gestión de negocios
            y el ejercicio de sus derechos.
          </p>
        </div>
        <ButtonLink
          icon="receipt_long"
          url="https://www.starsoftweb.com/factronweb"
          activeClassName={css.active}
          external
        >
          Consulta de comprobantes
          <br /> electrónicos aquí
        </ButtonLink>
      </Section>

      <Section className={css.aid}>
        <h1>¿Eres víctima de violencia de género?</h1>
        <div>
          <p>
            En Manuela Ramos apostamos por el derecho de las mujeres a decidir
            sobre sus cuerpos y sus proyectos de vida, teniendo como punto de
            partida su autonomía económica. Trabajamos para erradicar toda forma
            de violencia machista que afecte a las mujeres en su diversidad.
          </p>
          <ButtonLink
            icon="support"
            url="/asistencia-en-caso-de-violencia-de-genero"
            activeClassName={css.active}
          >
            Más información
          </ButtonLink>
        </div>
      </Section>
    </Layout>
  )
}
