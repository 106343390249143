import React from "react"
import css from "./Titles.module.css"

export default ({ title, description, icon, theme }) => {
  return (
    <section className={`${css.mainTitle} ${css[theme]} `}>
      {icon}
      <h1>{title}</h1>
      <div>{description}</div>
    </section>
  )
}
