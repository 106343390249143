import React from "react"
import Card from "../../components/card/Card"
import Date from "../../components/date/Date"
import css from "./Card.module.css"

export default ({ url, cover, date, title }) => {
  return (
    <Card url={url}>
      <img className={css.cover} src={cover} alt="img" />
      <Date date={date} />
      <h3>{title}</h3>
    </Card>
  )
}
